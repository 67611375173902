import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const BlogCard = ({ item: { id, cover, title, desc, para, catgeory, date } }) => {
  useEffect(() => {
    window.scrollTo(0, 0); // Asegurarse de que la página se muestre en la parte superior al cargar
  }, []);

  return (
    <Link to={`/blogsingle/${id}`} className="blogItem-link">
      <div className='items'>
        <div className='img'>
          <img src={cover} alt='Gallery Image' />
        </div>

        <div className='category flex_space'>
          <span>{date}</span>
          <label>{catgeory}</label>
        </div>

        <div className='details'>
          <h3>{title}</h3>
          <p className="description">{desc}</p>
        </div>

        <div>
          SABER MAS <i className="fa fa-long-arrow-right"></i>
        </div>
      </div>
    </Link>
  );
};

export default BlogCard;
