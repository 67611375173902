import React from 'react'
import "./Patrocinadores.css"
import Img1 from "../Images/gallery/gallery-1.png"
import Img2 from "../Images/gallery/gallery-2.png"
import Img3 from "../Images/gallery/gallery-3.png"
import Img4 from "../Images/gallery/gallery-4.png"
import Img5 from "../Images/gallery/gallery-5.png"

function Patrocinadores() {
  return (
 
         <div class="logos">
      <div class="logos-slide">
        <img src={Img1} />
        <img src={Img2} />
        <img src={Img3} />
        <img src={Img4} />
        <img src={Img5} />

      </div>

      <div class="logos-slide">
      <img src={Img1} />
        <img src={Img2} />
        <img src={Img3} />
        <img src={Img4} />
        <img src={Img5} />
      </div>
    </div>
   
  )
}

export default Patrocinadores