import React, { useState, useEffect, useRef } from "react"
import { Link } from "react-router-dom"
import BlogData from "../BlogData"
import EmptyFile from "../../Common/Empty/EmptyFile"
import HeadTitle from "../../Common/HeadTitle/HeadTitle"
import { useParams } from "react-router-dom"



import "./Modal.css"
import Footer from "../../../sections/Footer"
const BlogSingle = () => {

  const blogSingleRef = useRef(null);
  useEffect(() => {
    if (blogSingleRef.current) {
      // Desplazarse al elemento principal solo si existe
      blogSingleRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  const [isModalOpen, setIsModalOpen] = useState(false);


  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const { id } = useParams()
  const [item, setItem] = useState(null)

  useEffect(() => {
    let item = BlogData.find((item) => item.id === parseInt(id))
    if (item) {
      setItem(item)
    }
  }, [id])
  
  return (
    <>
      <div ref={blogSingleRef}>
        <HeadTitle  />
    
        {item ? (
      
          <section className='single-page top'>
            <div className='container grid-component'>
         
              {/* --------- main-content--------- */}

              <article className='content flex_space'>
                <div className='main-content' >
                  <img src={item.cover} alt='Fondo de Imagen'  id="sectionId"/>
             

                  <div className='category flex_space'>
           
                    <span>{item.date}</span>
                    <label>{item.catgeory}</label>
                  </div>

                  <h1 >{item.title}</h1>
                  <p>
                    {item.id === 1 ? item.pdesc : item.id === 2 ? item.pdesc : item.id === 3 ? item.pdesc : item.id === 4 ? item.pdesc : item.id === 5 ? item.pdesc : item.id === 6 ? item.pdesc
                      : "Sin descripcion"}
                  </p>
                  { /*  <h2>Valoramos la Educacion:</h2>

             <div className='para flex_space'>
                  <p>
                    {item.desc}</p>

                  <p>{item.para}</p>

                  </div> */ }
                </div>
                {/* --------- main-content--------- */}

                {/* --------- side-content--------- */}
                <div className='side-content'>
                <Link to='/blog' className='primary-btn back' >
                <i className='fas fa-long-arrow-alt-left'></i> Atras
              </Link>
                  <div className='category-list' id='blogSingleAnchor'>
                    <h1>Requisitos y Descripcion:</h1>
               
                    <hr /> 
                    <ul>
                      {item.id === 4 && (<>
                        <li>
                          <i className='far fa-play-circle'></i>
                          Categoria:  <b>{item.catgeory}</b>
                        </li>
                        <li>
                          <i className='far fa-play-circle'></i>
                          <b>Los Requisitos son :</b>
                          <ol>
                            <li><i className='far fa-arrow-right'></i>
                              Haber completado el Programa de Bachillerato en Teología del Seminario Teológico Nazareno del Cono Sur (Argentina, Uruguay y Paraguay), Instituto Bíblico Nazareno (Chile), o Seminario Nazareno Boliviano (Bolivia).
                            </li>
                            <li><i className='far fa-arrow-right'></i>
                              Haber completado los requisitos de inscripción/admisión al Programa. Para más información sobre este trámite, contacte a las autoridades de su Seminario en jurisdicción
                            </li>
                            <li><i className='far fa-arrow-right'></i>
                              Una vez terminada puedes acceder al programa de <b>MAESTRIA EN MINISTERIO.</b>
                            </li>
                          </ol>

                        </li>
                                               <li>
                          <a className="primary-btn" target="blank" href="https://wa.link/vnc0my">Informes Modalidad Presencial y Virtual </a>
           
                          </li>
                          <br/>
                          <li>
                          <a className="primary-btn" target="blank" href="https://wa.link/po1zro">Informes Modalidad Vitual Canvas</a>
           
                          </li>
                          <br/>
                        <button className="primary-btn" onClick={handleOpenModal}>
                          Ver Plan de Estudio
                        </button>
                        {isModalOpen && (
                          <div className="modal-overlay">
                            <div className="modal-content">
                              <h2>Plan de Estudio</h2>
                              {item.id === 4 && (
                                <>
                                  <div className="grid-plan" >

                                    <li>
                                      <i className='far fa-book'></i>
                                      Misionología
                                    </li>
                                    <li>
                                      <i className='far fa-book'></i>
                                      Psicología Ministerial
                                    </li>
                                    <li>
                                      <i className='far fa-book'></i>
                                      Panorama De Teología Contemporánea
                                    </li>
                                    <li>
                                      <i className='far fa-book'></i>
                                      Evangelismo Urbano
                                    </li>
                                    <li>
                                      <i className='far fa-book'></i>
                                      Griego Bíblico Instrumental
                                    </li>
                                    <li>
                                      <i className='far fa-book'></i>
                                      Teología Arminiana Wesleyana
                                    </li>
                                    <li>
                                      <i className='far fa-book'></i>
                                      Seminario En Teología Bíblica
                                    </li>
                                    <li>
                                      <i className='far fa-book'></i>
                                      Teología Del Ev. de la Iglesia
                                    </li>
                                    <li>
                                      <i className='far fa-book'></i>
                                      Teología Paulina
                                    </li>
                                    <li>
                                      <i className='far fa-book'></i>
                                      Administración y Liderazgo Cristiano
                                    </li>
                                    <li>
                                      <i className='far fa-book'></i>
                                      Homilética Avanzada En Santidad
                                    </li>
                                    <li>
                                      <i className='far fa-book'></i>
                                      Pedagogía Avanzada Y Comunicaciones
                                    </li>
                                    <li>
                                      <i className='far fa-book'></i>
                                      Seminario En Proyecto de Grado
                                    </li>
                                  </div>
                                </>
                              )}
                              <button className="primary-btn" onClick={handleCloseModal}>
                                Cerrar
                              </button>
                            </div>
                          </div>
                        )}

                      </>
                      )}
                      {item.id === 2 && (
                        <>
                          <li>
                            <i className='far fa-play-circle'></i>
                            Categoria:  <b>{item.catgeory}</b>
                          </li>
                          <li>
                            <i className='far fa-play-circle'></i>
                            Las clases se impartirán 100% en línea
                          </li>
                          <li>
                            <i className='far fa-play-circle'></i>
                            8 cursos regulares
                          </li>
                          <li>

                            <i className='far fa-play-circle'></i>
                            Estos tendrán una duración de <b> 6 semanas </b>(Un aproximado de mes y medio)  </li>
                           
                          <li>
                          <a className="primary-btn" target="blank" href="https://wa.link/un163r">Informes Educacion Cristiana</a>
           
                          </li>
                          <button className="primary-btn" onClick={handleOpenModal}>
                            Ver Plan de Estudio
                          </button>
                          
                          {isModalOpen && (
                            <div className="modal-overlay">
                              <div className="modal-content">
                                <h2>Plan de Estudio</h2>
                                {item.id === 2 && (
                                  <>
                                    <div className="grid-plan" >
                                      <li>
                                        <i className='far fa-book'></i>
                                        Discipulado : El sendero de la Gracia
                                      </li>
                                      <li>
                                        <i className='far fa-book'></i>
                                        Rol del M. desde la perspectiva de Jesús
                                      </li>
                                      <li>
                                        <i className='far fa-book'></i>
                                        Psicología Edu. y diversidad en el aula
                                      </li>
                                  
                                      <li>
                                        <i className='far fa-book'></i>
                                        La neurociencia en aprendizaje bíblico
                                      </li>
                                      <li>
                                        <i className='far fa-book'></i>
                                        Gestión de aula
                                      </li>
                                      <li>
                                        <i className='far fa-book'></i>
                                        Estrategias didácticas para el aprendizaje
                                      </li>
                                      <li>
                                        <i className='far fa-book'></i>
                                        Evaluación educativa
                                      </li>

                                    </div>
                                  </>
                                )}
                                <button className="primary-btn" onClick={handleCloseModal}>
                                  Cerrar
                                </button>
                              </div>
                            </div>
                          )}

                        </>
                      )}
                      {item.id === 3 && (
                        <>
                          <li>
                            <i className='far fa-play-circle'></i>
                            Categoria:  <b>{item.catgeory}</b>
                          </li>
                          <li>
                            <i className='far fa-play-circle'></i>
                            Incluye todos los cursos que corresponden al programa de Bachillerato en Teología dentro
                            de los cuales  están incluidos <b>los 30 cursos de ordenación </b>
                          </li>
                        
                      
                          <li>
                            <i className='far fa-play-circle'></i>
                            Poseer una credencial otorgada por <b>Un ministro Local </b> (Junta local de la Iglesia)
                            O   <b>Un ministro Licenciado</b> (Asamblea de Distrito por recomendación de la junta de Credenciales Ministeriales)

                          </li>
                          <li>
                            <i className='far fa-play-circle'></i>
                         Presentar el certificado de estudios Correspondiente a :
                  <ul type="disc"><li>Nivel M  <i className='far fa-play-circle'></i>Conclusion de educación primaria</li>
                  <li>Nivel A  <i className='far fa-play-circle'></i>Conclusion del tercer año de secundaria</li>
                  <li>Nivel U  <i className='far fa-play-circle'></i>Conclusion de educación secundaria o diploma correspondiente de su país</li></ul>
                             </li>
                          <li>
                            <i className='far fa-play-circle'></i>
                      Presentar 2 recomendaciones (Pastor de la iglesia y Superintendente de Distrito)
                          </li>
                          <li>
                            <i className='far fa-play-circle'></i>
                           Completar y entregar la <b>Solicitud de Ingreso</b>, Facilitada por el centro local.
                          </li>
                        </>
                      )}
                      {item.id === 1 && (
                        <>
                          <li>
                            <i className='far fa-play-circle'></i>
                            Categoria:  <b>{item.catgeory}</b>
                          </li>

                          <li>
                            <i className='far fa-play-circle'></i>
                          Modalidad Presencial :
                          <ul>
                            <li> Internado :<b> En Residencia</b> habitaciones del campus <i>SENABOL</i></li>
                            <li> Externo : Asisten solo a las clases en sus horarios correspondientes</li>
                            <li> Turno Mañana :  De Hrs: 9:00 am a 12:30 pm </li>
                            <li> Turno Tarde :  De Hrs: 14:30 pm a 18:00 pm </li>
                            <li> Desarrollo del curso :<b> Bimestral</b> </li>
                          </ul>
                          </li>

                          <li>
                            <i className='far fa-play-circle'></i>
                          Modalidad Virtual :
                          <ul>
                            <li> Plataforma <b> Zoom 1</b> : Dias Martes y Viernes </li>
                            <li> Plataforma <b> Zoom 2</b> : Dias Lunes y Jueves</li>
                            <li> A partir de Hrs: 19:00 pm a 21:00 pm </li>
                            <li> Duracion 6 semanas </li>
                          </ul>
                          </li>
                          
                          <li>
                            <i className='far fa-play-circle'></i>
                         Convenios <b>Seminario Nazareno Virtual</b>:
                          <ul>
                            <li> Cursos de aprendizaje en linea </li>
                            <li> Plataforma <b> Canvas</b> </li>
                            <li> Educación asincrónica </li>
                          </ul>
                          </li>
                          <li>
                          <a className="primary-btn" target="blank" href="https://drive.google.com/file/d/1DxnEiHvmdG13ThUhmyLV8BPy9W-IdjuE/view?usp=drive_link">Ver Materias de ordenación</a>
           
                          </li>
                          <br/>
                          <li>
                          <a className="primary-btn" target="blank" href="https://wa.link/xyy4ul">Informes Modalidad Presencial</a>
           
                          </li>
                          <br/>
                          <li>
                          <a className="primary-btn" target="blank" href="https://wa.link/8ob0sh">Informes Modalidad Virtual</a>
           
                          </li>
                          <br/>
                          <li>
                          <a className="primary-btn" target="blank" href="https://wa.link/yfntbj">Informes Plataforma Canvas (STNCS)</a>
           
                          </li>
                         
                        </>
                      )}
                        {item.id === 5 && (
                        <>
                          <li>
                            <i className='far fa-play-circle'></i>
                            Categoria:  <b>{item.catgeory}</b>
                          </li>

                          <li>
                            <i className='far fa-play-circle'></i>
                       Bachillerato con Mención
                       
                          </li>

                          <li>
                            <i className='far fa-play-circle'></i>
                        30 cursos de ordenación mas 15 de especialidad.
                     
                          </li>
                          
                          <li>
                            <i className='far fa-play-circle'></i>
                       Modalidad Virtual : Asíncrona 
                          </li>
                          <li>
                            <i className='far fa-play-circle'></i>
                     Duración 4 semanas (Cada curso)
                          </li>
                          <li>
                            
                    <a className="primary-btn" href="https://www.stnperu.com/" target="blank"> Mas Información</a>
                          </li>
                     
                        </>
                      )}
     {item.id === 6 && (
                        <>
                          <li>
                            <i className='far fa-play-circle'></i>
                            Categoria:  <b>{item.catgeory}</b>
                          </li>

                          <li>
                            <i className='far fa-play-circle'></i>
                     30 cursos de ordenación mas 15 de especialidad.
                       
                          </li>
           
                          <li>
                            <i className='far fa-play-circle'></i>
                       Modalidad Virtual : Asíncrona 
                          </li>
                          <li>
                            <i className='far fa-play-circle'></i>
                     Duración 6 semanas : 8 cursos anuales de especialidad por año
                          </li>
                          <li>
                            
                    <a className="primary-btn" href="https://seminarionazareno.com.ar/pastoral-juvenil/" target="blank"> Mas Información</a>
                          </li>
                          <br/>
                          <li>
                          <a className="primary-btn" target="blank" href="https://wa.link/uk185q">Informes Todas las Modaldiades</a>
           
                          </li>
                        </>
                      )}


                    </ul>
                  </div>
                </div>

                {/* --------- side-content--------- */}
              </article>
            </div>
          </section>
      
        ) : (
          <EmptyFile />
        )}
      </div>
  
    </>
  )
}

export default BlogSingle