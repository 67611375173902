import React from 'react';
import "./Shop.css"

import img1 from '../Images/Bolivia-1.jpg';
import img2 from '../Images/Bolivia-2.jpg';
import img3 from '../Images/Bolivia-3.jpg';
import img4 from '../assets/Images/picture-1.jpeg';
import img5 from '../Images/Bolivia-4.jpg'
import img6 from '../Images/Bolivia-5.jpg'
const Shop = () => {
  return (
    <div className='body'>
      <section className="dark">
        <div className="container">
          <h1 className="h1 text-center" id="pageHeaderTitle"     data-scroll data-scroll-speed="1" data-scroll-direction="horizontal">Proximamente Viajes Misioneros</h1>

          <article className="postcard dark blue"   data-scroll data-scroll-speed="-2" data-scroll-direction="horizontal">
            <a className="postcard__img_link" href="#">
              <img className="postcard__img" src={img5} alt="Image Title" />
            </a>
            <div className="postcard__text">
              <h1 className="postcard__title blue"><a href="#">Ciudad de La Paz - Bolivia</a></h1>
              <div className="postcard__subtitle small">
               
              </div>
              <div className="postcard__bar"></div>
              <div className="postcard__preview-txt">
              La Paz es la capital administrativa de Bolivia y se encuentra ubicada en el oeste del país, en un valle rodeado de montañas. Es la ciudad más alta del mundo, situada a una altitud promedio de aproximadamente 3.600 metros sobre el nivel del mar.
              <br />
<br />
              LA DURACIÓN SON 8 DÍAS

<br />
<br />
DÍA UNO  		Entrenamiento y capacitación, logistica y organización <br />
<br />
DÍA DOS 		Partida hacia el altiplano<br />
<br />

              </div>
             
            </div>
          </article>
          <article className="postcard dark blue"   data-scroll data-scroll-speed="2" data-scroll-direction="horizontal">
            <a className="postcard__img_link" href="#">
              <img className="postcard__img" src={img3} alt="Image Title" />
            </a>
            <div className="postcard__text">
              <h1 className="postcard__title blue"><a href="#">Altiplano - Aldeas y Comunidades Bolivianas</a></h1>
              <div className="postcard__subtitle small">
               
              </div>
              <div className="postcard__bar"></div>
              <div className="postcard__preview-txt">
              En algunas zonas rurales de Bolivia, especialmente en las áreas más alejadas y de difícil acceso, muchas aldeas carecen de servicios básicos como agua potable, electricidad, saneamiento adecuado y atención médica. La falta de infraestructuras y recursos limita las oportunidades de desarrollo y calidad de vida para las personas que viven en estas comunidades, contiuando con el recorrido tenemos: <br/>
              DIA TRES 		Actividades, Evangelismo y compartimiento con la comunidad<br />
<br />
DÍA CUATRO 		Partida hacia los Yungas (zona Cálidas del departamento)
Encuentro y coordinación con autoridades locales y educativas<br />
<br />
DÍA CINCO Y SEIS 	Actividades evangelisticas y de servicio comunitario con escuelas y la iglesia<br />
<br />


              </div>
             
            </div>
          </article>
          <article className="postcard dark blue"  data-scroll data-scroll-speed="-2" data-scroll-direction="horizontal">
            <a className="postcard__img_link" href="#">
              <img className="postcard__img" src={img2} alt="Image Title" />
            </a>
            <div className="postcard__text">
              <h1 className="postcard__title blue"><a href="#">Lugares Turisticos</a></h1>
              <div className="postcard__subtitle small">
               
              </div>
              <div className="postcard__bar"></div>
              <div className="postcard__preview-txt">
              La ciudad de La Paz es conocida por su impresionante paisaje montañoso, su rica historia y su vibrante cultura. Algunos de los lugares turísticos más destacados de la ciudad incluyen el centro histórico con su arquitectura colonial, la famosa calle de Sagárnaga, la Plaza Murillo con el Palacio de Gobierno, la Catedral y el teleférico que ofrece vistas panorámicas de la ciudad. Ya terminando el recorrido tenemos: <br/>
              <br/>

              DÍA SIETE 		Turismo y descanso<br />
<br />

DÍA OCHO		Culto de despedida y retorno a SENABOL (Seminario Nazareno Boliviano )

              </div>
             
            </div>
          </article>
    
          {/* Resto de los artículos de podcast... */}

        </div>
      </section>

   
    </div>
  );
};

export default Shop;
