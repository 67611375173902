import React from "react"
import HeadTitle from "../Common/HeadTitle/HeadTitle"
import AllBlog from "./AllBlog"
import Footer from "../../sections/Footer"
import Questions from "../Questions/Questions"



const Blog = () => {
  return (
    <>
      <HeadTitle />
         <div className='aboutCard  ' >
          <h1 className="ofert-title">
    <span>Oferta Académica </span> </h1>
      </div>
      <AllBlog />
      <Questions/>
    <Footer/>
    </>
  )
}

export default Blog