import React from 'react'
import HeadTitle from '../Common/HeadTitle/HeadTitle'
import Footer from '../../sections/Footer'
import Allitem from "./Allitem"
function Questions() {
  return (<>
    <div className='container'>
      <h1>Preguntas Frecuentes</h1>
    </div>
    <Allitem />

  </>
  )
}

export default Questions