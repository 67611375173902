import React from "react"
import "./About.css"

import HeadTitle from "../Common/HeadTitle/HeadTitle"
import AboutSection from "../../sections/About"
import Imagen from "../../Images/galeria-5.jpg"
import Footer from "../../sections/Footer"

const About = () => {
  const Youtube =()=>{
    window.open('https://youtu.be/8REJ9COCwgI')
  }
  return (
    <>
      <HeadTitle />
      <AboutSection />


      <section className='features top'>
        <div className='container aboutCard flex_space'>
          <div className='row row1'>
            <h1>
              Nuestro <span>Proposito</span>
            </h1>
         <p> El Seminario Nazareno Boliviano -  SENABOL sirve en América Latina, dando especial énfasis a la preparación y equipamiento de pastores, consejeros, misioneros y líderes afines al ministerio ya sea vocacional, bivocacional o avocacional.

              Tiene como finalidad capacitar y formar integralmente a los llamados al ministerio en su area de jurisdicción (Bolivia) por medio de sus programas residenciales, descentralizados y Virtuales. Ofrecemos formación bíblica, teológica, pastoral, histórica y general de acuerdo a las estipulaciones de los órganos competentes de la Iglesia de Nazareno.

            </p>
            <p>
              
             SENABOL concibe la educación teológica como un proceso formativo que integra todas las areas de la vida del ministro, tales como El area Espiritual ("el ser", "el carácter"), el area académica ("el conocer", el contenido), el area Ministerial ("el hacer", las capacidades) y el area de contextualization del evangelio ("el lugar", el contexto).

En este proceso educativo se busca desarrollar una actitud analítica, reflexiva y comprometida a partir de las verdades de la Palabra de Dios en respuesta a los desafíos contemporáneos.
            </p>
            <button className='secondary-btn' onClick={Youtube} >
              Saber Mas <i className='fas fa-long-arrow-alt-right'></i>
            </button>
          </div>
          <div className='row image'>
            <img src={Imagen} alt="Imagen" />
          </div>
        </div>
      </section>
      <Footer/>
    </>
  )
}

export default About
