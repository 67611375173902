import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import { Link } from 'react-router-dom';
import Logo from "../components/senabol-logo1.png";

const Section = styled.section`
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  background-color: ${(props) => props.theme.body};
  color: ${(props) => props.theme.text};
  position: relative;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 20vw;
    height: auto;
  }

  h3 {
    font-family: "Kaushan Script";
    font-size: ${(props) => props.theme.fontxxl};

    @media (max-width: 48em) {
      font-size: ${(props) => props.theme.fontlg};
    }
  }
`;

const FooterComponent = styled(motion.footer)`
  width: 80vw;

  @media (max-width: 48em) {
    width: 90vw;
  }

  ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 2rem;
    margin-top: 4rem;
    padding: 0 1rem;
    border-top: 1px solid ${(props) => props.theme.text};
    border-bottom: 1px solid ${(props) => props.theme.text};

    @media (max-width: 48em) {
      justify-content: center;
    }
  }

  li {
    padding: 2rem;
    font-size: ${(props) => props.theme.fontlg};
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      transform: scale(1.1);
    }

    @media (max-width: 48em) {
      padding: 1rem;
      font-size: ${(props) => props.theme.fontmd};
    }
  }
`;

const Bottom = styled.div`
  padding: 0.5rem 0;
  margin: 0 4rem;
  font-size: ${(props) => props.theme.fontlg};

  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    text-decoration: underline;
  }

  @media (max-width: 64em) {
    flex-direction: column;
    justify-content: center;
    span {
      transform: none !important;
    }
  }

  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontmd};
  }
`;

const Footer = () => {
  return (
    <Section>
      <LogoContainer>
        <img
          width="400"
          height="400"
          src={Logo}
          alt="Wibe"
        />
        <h3>
          Equipando Líderes para la Gran Comisión
        </h3>
      </LogoContainer>
      <FooterComponent
        initial={{ y: "-400px" }}
        whileInView={{ y: 0 }}
        viewport={{ once: false }}
        transition={{
          duration: 1.5,
        }}
      >
        <ul>
          <li aria-hidden="true">
          <Link to="/">Inicio</Link>
          </li>
     
          <li aria-hidden="true">
          <Link to="/about">Sobre Nosotros</Link>
          </li>
        
          <li aria-hidden="true">     
          <Link to="/contact">Contacto </Link>
          </li>
          <li>
          <a href='https://senabol.blogspot.com/'> Blog Noticias y Novedades</a>
          </li>
          <li>
          <a href='https://120w.xyz/virtualsenabol/' > PAE Senabol</a>
          </li>
          <li>
          <a href="https://senabolnuestrosprogramas.blogspot.com/">Programas y Admisiones</a>
          </li>
          <li>
          <a href='https://senabolcrfnazareno.blogspot.com/'> Centro R. Familiar Nazareno Aranjuez</a>
                    </li>
          
        </ul>
        <Bottom>
          <span>
            &copy; 2023. Senabol All Rights Reserved.
          </span>
          <span>
            Made with &hearts; by{" "}
            <a
              href=""
              target="_blank"
              rel="noopener noreferrer"
            >
              Senabol 2023
            </a>
          </span>
        </Bottom>
      </FooterComponent>
    </Section>
  );
};

export default Footer;
