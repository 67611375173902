import "locomotive-scroll/dist/locomotive-scroll.css";
import { Routes, Route, useLocation, Switch } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { useRef, useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import SinglePage from "./components/SinglePage/SinglePage";
import Blog from "./components/Blog/Blog";
import BlogSingle from "./components/Blog/blog-single-page/BlogSingle";
import Loader from "./components/Loader";
import ScrollTriggerProxy from "./components/ScrollTriggerProxy";
import Footer from "./sections/Footer";
import Home from "./components/HomePage";
import Marquee from "./sections/Marquee";
import NewArrival from "./sections/NewArrival";
import Shop from "./sections/Shop";
import GlobalStyles from "./styles/GlobalStyles";
import { dark } from "./styles/Themes";
import AboutPage from "./components/About/About";
import ServicesPage from "./components/Questions/Questions";
import LocomotiveScroll from "locomotive-scroll";
import "./App.css";
import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import Navbar from "./components/Navbar";
import Contact from "./components/Contact/Contact";

function App() {
  const containerRef = useRef(null);
  const [Loaded, setLoaded] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 3000);
  }, []);

  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={dark}>
        <main className="App" data-scroll-container ref={containerRef}>
          <ScrollTriggerProxy />
          <AnimatePresence>{Loaded ? null : <Loader />}</AnimatePresence>
          <Navbar />
          <Routes key={location.pathname} location={location}>
            <Route
              path="/"
              element={
                <LocomotiveScrollProvider
                  options={{
                    smooth: true,
                    smartphone: {
                      smooth: true,
                    },
                    tablet: {
                      smooth: true,
                    },
                  }}
                  containerRef={containerRef}
                >
                  <Home />
                </LocomotiveScrollProvider>
              }
            />
         <Route
              path="/about"
              element={
                <LocomotiveScrollProvider
                  options={{
                    smooth: true,
                    smartphone: {
                      smooth: true,
                    },
                    tablet: {
                      smooth: true,
                    },
                  }}
                  containerRef={containerRef}
                >
                  <AboutPage />
                </LocomotiveScrollProvider>
              }
            />
                <Route
              path="/questions"
              element={
                <LocomotiveScrollProvider
                  options={{
                    smooth: true,
                    smartphone: {
                      smooth: true,
                    },
                    tablet: {
                      smooth: true,
                    },
                  }}
                  containerRef={containerRef}
                >
                <ServicesPage />
                </LocomotiveScrollProvider>
              }
            />
              <Route
              path="/contact"
              element={
                <LocomotiveScrollProvider
                  options={{
                    smooth: true,
                    smartphone: {
                      smooth: true,
                    },
                    tablet: {
                      smooth: true,
                    },
                  }}
                  containerRef={containerRef}
                >
             <Contact />
                </LocomotiveScrollProvider>
              }
            />
        
          
            <Route path="/blogsingle/:id" element={<BlogSingle />} />
      
            <Route path="/blog" element={<Blog />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </main>
      </ThemeProvider>
    </>
  );
}

export default App;
