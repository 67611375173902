import { motion } from 'framer-motion';
import React, { useState, useEffect } from 'react';
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const NavContainer = styled(motion.div)`
  position: absolute;
  /* left: 50%; */
  top: ${(props) => (props.click ? '0' : `-${props.theme.navHeight}`)};
  transition: all 0.3s ease;
  /* transform: translateX(-50%); */
  z-index: 6;
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;

  
  @media (max-width: 40em) {
    top: ${(props) => (props.click ? '0' : `calc(-43vh - 4rem)`)};

  }
  @media (max-width: 25em) {
    top: ${(props) => (props.click ? '0' : `calc(-41vh - 4rem)`)};

  }
`;

const MenuBtn = styled.li`
  background-color: ${(props) => `rgba(${props.theme.textRgba},0.7)`};
  color: ${(props) => props.theme.body};
  width: 15rem;
  height: 2.5rem;

  border: none;
  outline: none;

  clip-path: polygon(0 0, 100% 0, 80% 100%, 20% 100%);

  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);

  font-size: ${(props) => props.theme.fontmd};
  font-weight: 600;

  /* border-end-start-radius: 50%; */

  /* border-end-end-radius: 50%; */

  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 0.3s ease;

  @media (max-width: 40em) {
    width: 10rem;
    height: 2rem;

  }
`;

const MenuItems = styled(motion.ul)`
  position: relative;
  height: ${(props) => props.theme.navHeight};
  background-color: ${(props) => props.theme.body};
  color: ${(props) => props.theme.text};
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;

  width: 100%;
  padding: 0 10rem;

  @media (max-width: 40em) {
    flex-direction:column;
    padding:2rem 0;
    height: 50vh;
  }
`;

const Item = styled(motion.li)`
  text-transform: uppercase;
  color: ${(props) => props.theme.text};

  @media (max-width: 40em) {
    flex-direction:column;
    padding:0.5rem 0;
    font-size: ${(props) => props.theme.fontxs};
    position: relative;
  }
  @media (max-width: 90em) {
    font-size: ${(props) => props.theme.fontxs};
  }
  
`;
const SubMenu = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: ${(props) => `rgba(${props.theme.bodyRgba},0.9)`};
  padding: 0;
  list-style: none;
  display: ${(props) => (props.show ? 'block' : 'none')};
  z-index: 1;
  @media (max-width: 40em) {
    
    position: fixed;
    top: auto;
    left: auto;
    width: 100%;
    max-height: 80vh;
    overflow-y: auto;
    padding: 1rem;
    margin-top: 1rem;
    border-radius: 10px;
  }
`;

const SubMenuItem = styled.li`
  padding: 10px;
`;

const Modal = styled.div`
  position: fixed;
  top: 0.5;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Color de fondo del modal */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  @media (max-width: 40em) {
    
    top: 0;
  }
`;
const Pae =styled.p`
text-transform: uppercase;
letter-spacing: 1px;
outline: 3px solid #FEF9E7 ;
padding: 15px 25px;
overflow: hidden;
transition: color 1s;
&:hover{
  color:  #A93226 ;
  font-weight: bolder;
}
&::before{
  content: '';
  position: absolute;
  top: 0;
  left: -1px;
  width: 0;
  height: 100%;
  background-color: #FEF9E7 ;
  transform: skewX(1deg);
  z-index: -1;
  transition: width 1s ;
}
&:hover::before{
width: 150px;

}
@media (max-width: 80em) {
  &:hover::before{
width: 130px;

}
}
  
@media (max-width: 40em)  and (ma-width: 24em){
  
  padding: 20px 25px;
 
  &:hover::before{
width: 130px;
height: 40px;

}
  }
`;
const ModalContent = styled.ul`
  background-color: ${(props) => `rgba(${props.theme.bodyRgba}, 0.9)`};
  padding: 0;
  list-style: none;
`;

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSubMenu = () => {
    setIsOpen(!isOpen);
  };
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  const [click, setClick] = useState(false);
 


  
  const { scroll } = useLocomotiveScroll();

  /*const handleScroll = (id) => {
    let elem = document.querySelector(id);
    // console.log(elem);
    setClick(!click);
    scroll.scrollTo(elem, {
      offset: '-100',
      duration: '2000',
      easing: [0.25, 0.0, 0.35, 1.0],
    });
  };*/

  return (
    <NavContainer
      click={+click}
      initial={{ y: `-100%` }}
      animate={{ y: 0 }}
      transition={{ duration: 2, delay: 5 /* 2 */ }}
    >
      <MenuItems
        drag="y"
        dragConstraints={{ top: 0, bottom: 70 }}
        dragElastic={0.05}
        dragSnapToOrigin
      >
        <MenuBtn onClick={() => setClick(!click)}>
          <span>MENU</span>
        </MenuBtn>
        <Item
          whileHover={{ scale: 1.1, y: -5 }}
          whileTap={{ scale: 0.9, y: 0 }}
         /* onClick={() => handleScroll('#home')}
      */  >
          {' '}
          <Link to="/">Inicio</Link>
        </Item>
        <Item
          whileHover={{ scale: 1.1, y: -5 }}
          whileTap={{ scale: 0.9, y: 0 }}
         /* onClick={() => handleScroll('#about')}
     */   >
          <Link to="/about">Sobre Nosotros</Link>
        </Item>
        <Item
          whileHover={{ scale: 1.1, y: -5 }}
          whileTap={{ scale: 0.9, y: 0 }}
         /* onClick={() => handleScroll('#shop')}
      */  >
          <a href="https://senabolnuestrosprogramas.blogspot.com/">Programas y Admisiones</a>
        </Item>

        <Item onClick={toggleModal}>
  {' '}
  <Link to="/">Material de Estudio</Link>
  {isOpen && (
    <Modal>
      <ModalContent>
        <SubMenuItem>
          <a href="https://www.whdl.org/es">Biblioteca Wesleyana especializada</a>
        </SubMenuItem>
        <SubMenuItem>
          <a href="https://www.samnaz.org/recursos/">Recursos de la Región SAM</a>
        </SubMenuItem>
      </ModalContent>
    </Modal>
  )}
</Item>

        <Item
          whileHover={{ scale: 1.1, y: -5 }}
          whileTap={{ scale: 0.9, y: 0 }}
         /* onClick={() => handleScroll('.new-arrival')}*/
        >
          {' '}
          <Link to="/contact">Contacto </Link>
        </Item>
        <Item
          whileHover={{ scale: 1.1, y: -5 }}
          whileTap={{ scale: 0.9, y: 0 }}
         /* onClick={() => handleScroll('.new-arrival')}*/
        >
          {' '}
          <a href='https://senabol.blogspot.com/'> Noticias y Novedades</a>
        </Item>
        <Item
          whileHover={{ scale: 1.1, y: -5 }}
          whileTap={{ scale: 0.9, y: 0 }}
         /* onClick={() => handleScroll('.new-arrival')}*/
        >
          {' '}
          <a href='https://senabolcrfnazareno.blogspot.com/'> CRf Nazareno Aranjuez</a>
        </Item>
        <Item
          whileHover={{ scale: 1, y: 1 }}
          whileTap={{ scale: 0.9, y: 0 }}
         /* onClick={() => handleScroll('.new-arrival')}*/
        >
          {' '}
          <a href='https://120w.xyz/virtualsenabol/' > <Pae> PAE Senabol</Pae></a>
        </Item>
      </MenuItems>
    </NavContainer>
  );
};

export default Navbar;
