import React, { useEffect } from 'react';
import Home from '../sections/Home';
import About from '../sections/About';
import Marquee from '../sections/Marquee';
import Shop from '../sections/Shop';
import NewArrival from '../sections/NewArrival';
import Valores from './Works/Works';
import Patrocinadores from '../sections/Patrocinadores';
import Footer from '../sections/Footer';

function HomePage() {


  return (
    <>
      <Home />
      <About />
      <Marquee />
      <Valores />


<Shop/>
      <Patrocinadores />
      <Footer />
    </>
  );
}

export default HomePage;
