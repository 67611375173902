import React from "react"
import HeadTitle from "../Common/HeadTitle/HeadTitle"
import ContactForm from "./ContactForm.jsx"
import Footer from "../../sections/Footer"

const Contact = () => {
  return (
    <>
    <HeadTitle/>
      <ContactForm/>
      <Footer/>
    </>
  )
}

export default Contact
