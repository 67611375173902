import React, { useState } from "react";
import "./Contact.css";

const ContactForm = () => {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allValue, setAllValue] = useState([]);
  const formSubmit = (e) => {
    e.preventDefault();

    const newValue = { fname, lname, phone, email, subject, company, message };
    setAllValue([...allValue, newValue]);
    setIsModalOpen(true);
    setFname("");
    setLname("");
    setPhone("");
    setEmail("");
    setSubject("");
    setCompany("");
    setMessage("");

    const emailBody = `
      Nombre: ${fname} ${lname}\n
      Teléfono: ${phone}\n
      Email: ${email}\n
      Motivo: ${subject}\n
      Fecha: ${company}\n
      Mensaje: ${message}
    `;

    const mailtoLink = `mailto:seminarionazarenoboliviano@gmail.com?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(emailBody)}`;

    window.location.href = mailtoLink;
  };
  const closeModal = () => {
    // Cerrar el modal y restablecer el estado del formulario
    setIsModalOpen(false);
  };

  return (
    <>
      <section className="contact mtop rcontact">
        <div className="container flex">
          <div className="main-content">
            <h2>Contactanos</h2>
            <p>Puedes enviarnos un mensaje, nos pondremos en contacto contigo a la brevedad.</p>

            <form onSubmit={formSubmit}>
              <div className="grid1">
                <div className="input">
                  <span>
                    Nombres <label>*</label>
                  </span>
                  <input type="text" name="fname" value={fname} onChange={(e) => setFname(e.target.value)} required />
                </div>
                <div className="input">
                  <span>
                    Apellidos<label>*</label>
                  </span>
                  <input type="text" name="lname" value={lname} onChange={(e) => setLname(e.target.value)} required />
                </div>
                <div className="input">
                  <span>
                    Numero de Celular <label>*</label>
                  </span>
                  <input type="number" name="phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
                </div>
                <div className="input">
                  <span>
                    Email o Correo <label>*</label>
                  </span>
                  <input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                </div>
                <div className="input">
                  <span>Motivo</span>
                  <input type="text" name="subject" value={subject} onChange={(e) => setSubject(e.target.value)} />
                </div>
                <div className="input">
                  <span>Fecha</span>
                  <input type="date" name="date" value={company} onChange={(e) => setCompany(e.target.value)} />
                </div>
              </div>
              <div className="input inputlast">
                <span>
                  Escribe tu Mensaje <label>*</label>
                </span>
                <textarea cols="30" rows="10" name="message" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
              </div>
              <button className="primary-btn">Enviar</button>
            </form>
          </div>

          <div className="side-content">
            <h3>Estamos Ubicados:</h3>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3824.3340413500437!2d-68.09555752397864!3d-16.55968524226658!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x915f26d67ba7fc29%3A0xf462eb6b2fdfec31!2sSENABOL!5e0!3m2!1ses-419!2sbo!4v1687569637036!5m2!1ses-419!2sbo"
              width="350"
              height="350"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>

            <br />

            <h3>Mensajes al:</h3>
            <span>seminarionazarenoboliviano@gmail.com</span>
            <br />
            <span>2740559</span>
            <br />

            <div className="icon">
              <h3>Siguenos en Nuestras Redes</h3>

              <div>
            <a href="https://www.facebook.com/bolivia.seminario" target="blank">   <i className="fab fa-facebook-f"></i></a> 
          
                    
                <a href="https://www.youtube.com/@seminarionazarenoboliviano1740" target="blank">  <i className="fab fa-youtube"></i></a> 
              </div>
            </div>
          </div>
        </div>
      </section>

      {isModalOpen && (
        <section className="modal">
          <div className="modal-content">
            <div className="success-message">
              <h1>¡Sigue el enlace y Envia el mensaje!</h1>
              <p>Gracias por contactarnos. Nos pondremos en contacto contigo pronto.</p>
              <button className="primary-btn" onClick={closeModal}>Cerrar</button>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ContactForm;
