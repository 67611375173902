import img1 from "../../Images/works/work-1.png"
const Wdata = [
    {
      id: 1,
      cover: "fas fa-biblie",
      title: "Compromiso Cristiano",
      desc: "Como parte de la Iglesia del Nazareno: Hacer discipulos a semenjanza de Cristo en las naciones.",
    },
    {
      id: 1,
      cover: "https://lh3.googleusercontent.com/pw/AJFCJaXPwuQz4eXR8wF6a72xP-EboKvoBlACDHy2Uj__fz79J9BFQdgljJfNSUkN4qoe3aWKx8KTnP5z9ptMYIqWsgXvb4-YO7698MiaK4kZjIl_4VYPPBZCCj7cYmHgPyH_YQpy1_Cfd6rlZI7tuvNfYFCHh_vlJpmTUkW6KDdN-5NEIP9cS6rR6ogrQpuEk7FheBWXWn6IsKfXfCieV8L4rIlT-lFY_Qfyrz5bQUQIRoldgQnIM1BgmRedAS-SqIYUE9ROnkCisfbG-SN4Vd9cnbHyTixjpVKleOtQpXKdUyXM6VKbt3uMQWCb3mRGsliwZNfMlmRIEnKtydfv7fwqQwWhm2o9RpY1zEw7xJnuxrvcH0CPILbeGYQ-QrgnhC6tsNVpj7pURWErna_5gM_rE2eDFDk7Cj6Q1vdTOLBPodbhRpRyiI0W_0mJ5yuXMU2A4X2Om7cap4exUFnp8O3xAMaSYNo4jpA3uc9nbbLyTp9GH4uYkgc91qn6aBIqu3oxqmGeL_8bmaG1S134GKSkT1aIT74nhl-jRNushl9S7qInX6tjyQt3IqzAEKRA0NI5qA7_WL3mWf177B3eXYHaW_fO9wO_Uux1GXXrrbGVsI6GGPi2Ikyngy7x2wAAd8Xqc4Ib8_31eVggInbW5mknDSlPEVgLtg9QTPBA4qZ-Gby2QLa_8adOncwheo-EbJ2teCxhRwzhYZ5edkiOBbpjTthB7YJLSaVnJiNvzTtoiIu_AtUQKWx6C5vPpDwGxCzJ6o2sVX-Eh7Z9EIErZYXmlMjO1zttU5MjtP_YkPe5fuQRIzX02dStBhWFwTjLttMb96ljCKEeW7KrcrcuXWEavNSmiZVVNiZdFWTnn29NlUm843U0hzyzJubEW1JD9tnnwuziUXoO4b61NPvRCw86dyLz85MbF9GY_y2qcAqfDphKXcKlgCwTJtm22WePhH7z4hTpFW7SFAWZXeAIIHIf2vfgaOkPbnIB=w96-h96-s-no?authuser=0",
      title: "Educacion de por Vida",
      desc: "Proveer una educacion teologica basada exclusivamente en la palabra de Dios.",
    },
    {
      id: 1,
      cover: "https://lh3.googleusercontent.com/pw/AJFCJaUSkyBch6MSuCaBtg1yHqKN5Z8M3jBeg75aQGuIXpQCLKEQe4fJ0KLZNT9UWppqO0LENK02HUEhIWfOrBL4zPU6RJC8UDO2mZej_FYB2g3imfQIsi97SYIX91m6LGgoyV42WALW-GPc7qk4MqN4ZdDQ424o6AtRtZd-YSjCFzzfWZfPC4m5JLB2VDx2L3I1OH28jdRzhTcgIeN-1Q7PP0XwrczpHDkzQQB9HDhapIBPJ79p1PYnH90ZIAljTLsvEHimNk0xssajgRQhAghtjYw44-vhXOWy3uOgtfJ6U4LTwQQLvnnu7DZySF2wIfpeOoiZsDua2ybh5ORyUB-x873PCzEjmA2r-D_tmCo-Of1DgGiDMFv6kRmbb1sVEcrMWfGWFkGFufkjy4EHJd5uwxcx5dhpVhSn0wOe42YFy3ZO5g6j8DwnB7446icSETEUm5yNtwjGlR7ad9PUiDH2Zu8suXwO4M5przocbSrNMLDMDHA0-SPpqZJZQOqARmP0rSTCo--uamqcW1huc-fu9pcSlvzQWYOgcBzpDuQA25Falm2TL1RPejlMTghjexY22deUsoM7bcuTosW9b30aItIG-Atr3Fq-StdXvIbTJ4JhNEqcS1aMikU02PdTmAlz4HxOKXT_7JyX-u1Oa9ZUT5LJC_I2S5ol5WrgsNhNQUjraMU1boowPJ54zrH5pE8JsfxlXep0EfWZEXbFLqDRmbNtUtES3ryriLCtCfRw-I4lYbeONqssHBNan0KaIT67GQsoT5zR5vLIWMpchejr9fbdhEZdtWyWwktG63TUrfrGL-QYRBGsICyzUFvFTGkASubmyK-1Wl1g-j8y5hhha5Q0dbCLhKtlBTdKaxUK6PrBr_xRnyoylN_BZovqt1bk_ZC3vYJefQ5caBV86jfcmyHSMMOFRLu3CkiebDy3twJsmmy6v5jMI8TnydNCsj9pvVGFmNfQj4zSc9K6RHYaySKd8h2qQYLi=w96-h96-s-no?authuser=0",
      title: "Sostenibilidad",
      desc: "Conosolidar al estudiante la Centralidad de la Palabra de Dios escrita y en palabra viva Jesucristo, dentro de una hermeneutica contextual solida. Con el fin de mantener el sentido biblico frente a tergiversaciones de la religiosidad contemporanea.",
    },
    {
      id: 1,
      cover: "https://lh3.googleusercontent.com/pw/AJFCJaUSkyBch6MSuCaBtg1yHqKN5Z8M3jBeg75aQGuIXpQCLKEQe4fJ0KLZNT9UWppqO0LENK02HUEhIWfOrBL4zPU6RJC8UDO2mZej_FYB2g3imfQIsi97SYIX91m6LGgoyV42WALW-GPc7qk4MqN4ZdDQ424o6AtRtZd-YSjCFzzfWZfPC4m5JLB2VDx2L3I1OH28jdRzhTcgIeN-1Q7PP0XwrczpHDkzQQB9HDhapIBPJ79p1PYnH90ZIAljTLsvEHimNk0xssajgRQhAghtjYw44-vhXOWy3uOgtfJ6U4LTwQQLvnnu7DZySF2wIfpeOoiZsDua2ybh5ORyUB-x873PCzEjmA2r-D_tmCo-Of1DgGiDMFv6kRmbb1sVEcrMWfGWFkGFufkjy4EHJd5uwxcx5dhpVhSn0wOe42YFy3ZO5g6j8DwnB7446icSETEUm5yNtwjGlR7ad9PUiDH2Zu8suXwO4M5przocbSrNMLDMDHA0-SPpqZJZQOqARmP0rSTCo--uamqcW1huc-fu9pcSlvzQWYOgcBzpDuQA25Falm2TL1RPejlMTghjexY22deUsoM7bcuTosW9b30aItIG-Atr3Fq-StdXvIbTJ4JhNEqcS1aMikU02PdTmAlz4HxOKXT_7JyX-u1Oa9ZUT5LJC_I2S5ol5WrgsNhNQUjraMU1boowPJ54zrH5pE8JsfxlXep0EfWZEXbFLqDRmbNtUtES3ryriLCtCfRw-I4lYbeONqssHBNan0KaIT67GQsoT5zR5vLIWMpchejr9fbdhEZdtWyWwktG63TUrfrGL-QYRBGsICyzUFvFTGkASubmyK-1Wl1g-j8y5hhha5Q0dbCLhKtlBTdKaxUK6PrBr_xRnyoylN_BZovqt1bk_ZC3vYJefQ5caBV86jfcmyHSMMOFRLu3CkiebDy3twJsmmy6v5jMI8TnydNCsj9pvVGFmNfQj4zSc9K6RHYaySKd8h2qQYLi=w96-h96-s-no?authuser=0",
      title: "Unidad",
      desc: "Es hora de olvidar las diferencias; entender la unidad que Dios nos ha concedido; demostrarla en la práctica; y ganar este mundo para nuestro Salvador.",
    },
  ]
  export default Wdata