import React from "react"

const Card = (props) => {
  return (
    <>
      <div className='box'>
        <div className='img'>
        <i className="fas fa-bible" style={{ color: "#e42f2f", size: "20rem" }} />

        </div>
        <div className='details'>
          <h2>{props.title}</h2>
          <p>{props.desc}</p>
         
        </div>
      </div>
    </>
  )
}

export default Card   