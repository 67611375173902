import React from "react"
import "./Works.css"
import "./MostPopular.css"
import Card from "./Card"
import Wdata from "./Wdata"

const Works = () => {
  return (
    <>
      <section className='popular works'>
        <div className='container'>
          <div className='heading'>
            <h1>Valores que nos Identifican </h1>
            <div className='line'></div>
          </div>

          <div className='content grid'>
            {Wdata.map((value, index) => {
              return <Card key={index} cover="fas fa-bible" title={value.title} desc={value.desc} />
            })}
          </div>
        </div>
      </section>
    </>
  )
}

export default Works