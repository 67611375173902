import img1 from "../../assets/Iamgenes/aa.jpg"
const BlogData = [
  {
    id: 1,
      date: "2023",
        catgeory: "Bachillerato",
          title: "Bachillerato en Teología",
            cover: "https://cdn.pixabay.com/photo/2015/07/31/11/45/library-869061_1280.jpg",
              desc: "Son estudios de carácter formal, a nivel superior, enfocado en aquellos que están desarrollando ministerios.  Para el ejercicio de la vocación ministerial en respuesta al llamado de Dios.'",
                para: " Informes Modalidad Presencial : https://wa.link/o1qv5y",
    pdesc:" Presentamos este programa de Bachillerato Superior en Teología desarrollado con todos los estándares académicos que exige una institución de tercer nivel.Nuestro bachillerato no tiene relación con el de nivel secundario dictado por las entidades educativas locales.  ",
              },
  {
    id: 2,
      date: "2023",
        catgeory: "Diplomado",
          title: "Diplomado en Educación Cristiana",
          cover: "https://cdn.pixabay.com/photo/2017/07/31/11/21/people-2557399_1280.jpg",
              para: "Los estudiantes son formados y entrenados como seguidores de Jesús y líderes que viven y sirven desde una perspectiva cristiana. El Seminario Nazareno Boliviano sirve de forma activa en la misión de la Iglesia del Nazareno de 'hacer discípulos semejantes a Cristo en las naciones'",
                desc: "Consisite en entrenar discipuladores en un mano a mano entre la escuela Dominical y la educacion Teologica (Region Sudamerica)",
          pdesc:" Nuestro Deseo: Capacitar a todos los llamados a ser los formadores en la educación cristiana, con recursos y estrategias que respondan a los desafíos educativos de la iglesia en una Sudamérica posmoderna.",
              },
  
    {
      id: 3,
        date: "2023",
          catgeory: "ETED",
            title: "Programa de Estudios Teológicos Descentralizados - ETED",
            cover: "https://cdn.pixabay.com/photo/2017/03/27/13/03/book-2178586_1280.jpg",
                para: "Los estudiantes son formados y entrenados como seguidores de Jesús y líderes que viven y sirven desde una perspectiva cristiana. El Seminario Nazareno Boliviano sirve de forma activa en la misión de la Iglesia del Nazareno de 'hacer discípulos semejantes a Cristo en las naciones'",
                  desc: "Permite a la persona que ha sido llamada por Dios recibir la capacitación básica para ejercer su labor ministerial en su propio contexto.",
                  pdesc:"El propósito de este programa es proveer formación integral a las personas que testifican que Dios les ha llamado al ministerio cristiano. Los cursos serán impartidas en base a una calendarización anual previamente establecida en el distrito y aprobada por la institución teológica en jurisdicción de SENABOL. El plan de estudios de ETED para toda la region comprende : CURSOS REQUERIDOS PARA ORDENACIÓN, CURSOS COMUNES A TODAS LAS INSTITUCIONES TEOLÓGICAS DE SUDAMERICA, CURSOS CONTEXTUALIZADOS. El ingreso a este programa esta abierto a todos los miembros de la iglesia que cumplan los  requisitos de su institución en jurisdicción SENABOL (VER REQUISITOS Y DESCRIPCIÓN)",
                },

              {
                id: 4,
                  date: "2023",
                    catgeory: "Licenciatura",
                      title: "Licenciatura en Teología ",
                      cover: "https://cdn.pixabay.com/photo/2015/11/19/21/10/glasses-1052010_1280.jpg",
                          para: "Los estudiantes son formados y entrenados como seguidores de Jesús y líderes que viven y sirven desde una perspectiva cristiana. El Seminario Nazareno Boliviano sirve de forma activa en la misión de la Iglesia del Nazareno de 'hacer discípulos semejantes a Cristo en las naciones'",
                            desc: "Es la continuacion del programa Bachillerato en Teologia, su duracion puede ser de 2 años promedio, sujeto a las modalidades que el seminarista escoja (Presencial o Virtual)   ",
                            pdesc: "Es la continuación del programa de Bachillerato en Teología, su duración puede ser de 2 años promedio, sujeto a las modalidades que el seminarista escoja (presencial o virtual). En este programa conocerás un estudio profundo de las Escrituras, así como su correcta interpretación contextual. Para cursar en este programa se requiere haber completado el Programa de Bachillerato en Teología. Está formado por 13 materias. ",
                          },
  
{
  id: 5,
    date: "2023",
      catgeory: "Bachillerato",
        title: "Bachillerato/Diplomado en Teologia con énfasis en Desarrollo de la Niñez y adolescencia",
          cover: "https://cdn.pixabay.com/photo/2015/12/15/06/42/kids-1093758_1280.jpg",
            para: "Los estudiantes son formados y entrenados como seguidores de Jesús y líderes que viven y sirven desde una perspectiva cristiana. El Seminario Nazareno Boliviano sirve de forma activa en la misión de la Iglesia del Nazareno de 'hacer discípulos semejantes a Cristo en las naciones'",
            pdesc:"Este Programa realizara la reflexion biblica teologica, visibilizara la ninhez que viven en zonas de riesgo. La accion de proteccion y dignificacion de la ninhez y adolescencia. Las Materias son dictadas por profesores con experiencia academica pastoral, la inscripcion incluye la bibliografia en formato electronico acceso a la plataforma educativa y soporte tecnico", 
            desc: "El Bachillerato en DINA aporta y da herramientas a la pastoral de la Niñez y Adolescencia en un tiempo de la postmodernidad y la globallizacion marcada por una cultura digital.  ",
  },
  {
    id: 6,
    date: "2023",
    catgeory: "Bachillerato",
    title: "Bachillerato/Diplomado en Pastoral Juvenil",
    cover: "https://cdn.pixabay.com/photo/2016/06/01/06/26/open-book-1428428_1280.jpg",
    para: "Los estudiantes son formados y entrenados como seguidores de Jesús y líderes que viven y sirven desde una perspectiva cristiana. El Seminario Nazareno Boliviano sirve de forma activa en la misión de la Iglesia del Nazareno de 'hacer discípulos semejantes a Cristo en las naciones'",
    desc: "Este programa busca satisfacer la necesidad de formar líderes capacitados en el ámbito de la pastoral de jóvenes y adolescentes, brindando una educación de excelencias. ",
    pdesc: "El Programa de Bachillerato en Pastoral Juvenil y Diplomado en Ministerios Juveniles es el resultado de un trabajo en conjunto entre la Juventud Nazarena Internacional y el Ministerio de Educación Teológica Regional de la Iglesia del Nazareno Región Sudamérica, en respuesta a la necesidad de contar con un liderazgo capacitado con excelencia en el conocimiento de la pastoral de jóvenes y adolescentes.Los cursos se dictarán en modalidad virtual, y cada curso tendrá una duración de seis semanas, exceptuando el “Seminario Introductorio”, que será de cuatro semanas.",
  },


]

export default BlogData