import React from "react";
import "./HeadTitle.css";
import { useLocation, Link } from "react-router-dom";


const HeadTitle = () => {
  const location = useLocation();
  
  function getLanguage() {
    const navigatorLanguage = navigator.language || navigator.userLanguage;
    return navigatorLanguage.split('-')[0];
  }
  
  const language = getLanguage();
  const translations = {
    en: {
      home: "Home",
      about: "About",
      contact: "Contact",
      destinations:"Destinations",
     singlepage:"SinglePage",
     blog:"Programas",
     blogsingle:"More Programas",
     testimonial:"Questions",
     gallery:"Gallery"
    },
    es: {
      home: "Inicio",
      about: "Sobre nosotros",
      contact: "Contacto",
      destinations:"Misioneria",
     singlepage:"Destinos",
     blog:"Programas",
     blogsingle:"Mas Programas",
     testimonial:"Preguntas Frecuentes",
     gallery:"Galeria de Fotos"
    }
  };
  
  const translatedText = translations[language][location.pathname.split("/")[1]];

  return (
    <>
      <section className='image-heading'>
        <div className='container'>
          <h1>SENABOL - Seminario Nazareno Boliviano</h1>

  
        </div>
      </section>
    </>
  );
}

export default HeadTitle;
