const Tdata = [
    {
      id: 1,
      desc: "¿CUAL ES EL COSTO DE LOS CURSOS Y CUANDO EMPIEZAN?  ---> Las autoridades del seminario en jurisdicción le informaran sobre el calendario y costos." ,
      name: "USUARIO DESCONOCIDO",
      profile: "../../Images/profile/profile1.jpg",
      post: "Seminarista",
    },
    {
      id: 2,
      desc: "¿COMO SON LOS CURSOS EN LA MODALIDAD VIRTUAL?  ---> Se dictan a distancia por internet mediante una plataforma educativa virtual, cursos asincrónicos  .",
      name: " GABRIELA ",
      profile: "/images/profile/profile2.jpg",
      post: "Profesora",
    },
    {
      id: 3,
      desc: "¿CUALQUIER PERSONA PUEDE ESTUDIAR EN SENABOL? ---> Toda persona con ganas de servir en la obra del Señor es bienvenida pero para garantizar que tus estudios sean aprovechados al máximo, es recomendable tener cierta experiecia en el servicio ministerial.",
      name: "JAVIER VALLEJOS",
      profile: "/images/profile/profile3.jpg",
      post: "Ingeniero",
    },
    {
      id: 4,
      desc: "¿PORQUE ES IMPORTANTE ESTUDIAR PARA SERVIR EN EL MINISTERIO? ----> Como cualquier carrera o vocación, el estudio teológico formal garantiza el potencial de tus habilidades en el servicio ministerial, estar preparado para la obra del Señor es fundamental. ",
      name: "SUSANA",
      profile: "/images/profile/profile4.jpg",
      post: "Ama de Casa",
    },
    {
      id: 5,
      desc: "¿NECESITO HABER ESTUDIADO TEOLOGÍA CON ANTERIORIDAD? ----> No, nuestros programas tienen cursos especializados para todos aquellos que esten comenzando a profundizar en el servicio ministerial, podras especializarte en Trabajo con Jóvenes, Misiones o el Pastorado formal",
      name: "MICHAEL DAVID",
      profile: "/images/profile/profile5.jpg",
      post: "Contador",
    },
  
  ]
  
  export default Tdata