import React from "react";
import styled from "styled-components";

import img1 from "../assets/Images/picture-1.jpeg";
import img2 from "../assets/Images/picture-13.jpeg";
import img3 from "../assets/Images/picture-4.jpeg";

const Section = styled.section`
  min-height: 100vh;
  width: 80vw;
  margin: 0 auto;

  position: relative;

  display: flex;
  @media (max-width: 48em) {
    width: 90vw;
  }

  @media (max-width: 30em) {
    width: 100vw;
  }
  /* justify-content: center;
  align-items: center; */
`;

const Left = styled.div`
  width: 50%;
  font-size: ${(props) => props.theme.fontnd};
  font-weight: 300;
  position: relative;
  z-index: 5;
  margin-top: 20%;

  @media (max-width: 64em) {
    width: 80%;

    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    margin: 0 auto;

   
    font-weight: 600;

    backdrop-filter: blur(2px);
    background-color: ${(props) => `rgba(${props.theme.textRgba},0.4)`};
    border-radius: 20px;
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontmd};
  }
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontsm};
    padding: 2rem;
    width: 70%;
  }
`;

const Right = styled.div`
  width: 50%;
  position: relative;
  /* min-height: 100vh; */

  img {
    width: 100%;
    height: 100%;
  }

  .small-img-1 {
    width: 40%;
    position: absolute;
    right: 95%;
    bottom: 10%;
  }
  .small-img-2 {
    width: 40%;
    position: absolute;
    left: 80%;
    top: 30%;
  }
  @media (max-width: 64em) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }

    .small-img-1 {
      width: 30%;
      height: auto;
      left: 5%;
      bottom: 10%;
    }
    .small-img-2 {
      width: 30%;
      height: auto;

      position: absolute;
      left: 60%;
      bottom: 20%;
    }
  }
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontBig};
  font-family: "Kaushan Script";
  font-weight: 300;
  /* text-transform: capitalize; */

  position: absolute;
  top: .2rem;
  left: 5%;
  z-index: 5;

  span {
    display: inline-block;
  }

  @media (max-width: 64em) {
    font-size: ${(props) => `calc(${props.theme.fontBig} - 5vw)`};
    top: 0;
    left: 0%;
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxxxl};
  }
`;

const About = () => {
  return (
    <Section id="fixed-target" className="about">
      <Title
        data-scroll
        data-scroll-speed="-2"
        data-scroll-direction="horizontal"
      >
      Sobre Nosotros
      </Title>
      <Left data-scroll data-scroll-sticky data-scroll-target="#fixed-target">
      Somos SENABOL - Seminario Nazareno Boliviano. Desde 1947 comenzó la educación teológica con el nombre de Escuela Bíblica. Posteriormente, en 1953, se elevó a Instituto Bíblico hasta la década de los setenta.

Con el espíritu de seguir mejorando académicamente, en 1980 se reinicia con el nombre de <b>Seminario Nazareno Boliviano</b>. En 2003 se cambió el nombre a "Seminario Teológico Nazareno de Bolivia", continuando en el mismo nivel académico.
A  partir del 2017 nuevamente se retoma el nombre de Seminario Nazareno Boliviano SENABOL
        <br />
      
        <p><h2><span>Visión</span></h2> </p>
        <p>Nuestra Vision es formar y equipar de manera eficiente e integral hombres y mujeres enteramente santificados.
            y competentes para la Gloria de Dios. </p>

   
       
        <p><h2><span>Misión</span></h2> </p>
        <p>Nuestra misión es servir primordialmente a la iglesia del Nazareno en Bolivia, formando teológica e integralmente a personas con vocación de servicio, desde una perspectiva Arminio - Wesleyana.
          </p>
        
      </Left>

      <Right>
        <img width="400" height="600" src={img1} alt="About Us" />
   
      </Right>
      
    </Section>
    
  );
};

export default About;
